// note: the id's are no longer used for classification, but are kept here for compatibility with the old code

export default {
  0: 'ACL tear (anterior cruciate ligament tear), right',
  1: 'ACL tear (anterior cruciate ligament tear), left',
  2: 'ACL tear (anterior cruciate ligament tear), bilateral',
  3: 'acne',
  4: 'adjustment disorder',
  5: 'agoraphobia',
  6: 'alopecia',
  7: 'ALS (amyotrophic lateral sclerosis)',
  8: 'anal cancer',
  9: 'anal or perianal fistula (fistula-in-ano)',
  10: 'ankle replacement (ankle arthroplasty), right',
  11: 'ankle replacement (ankle arthroplasty), left',
  12: 'ankle replacement (ankle arthroplasty), bilateral',
  13: 'ankle sprain, right',
  14: 'ankle sprain, left',
  15: 'ankle sprain, bilateral',
  16: 'ankylosis in ankle, right',
  17: 'ankylosis in ankle, left',
  18: 'ankylosis in ankle, bilateral',
  19: 'ankylosis in elbow, right',
  20: 'ankylosis in elbow, left',
  21: 'ankylosis in elbow, bilateral',
  22: 'ankylosis in hand or fingers, right',
  23: 'ankylosis in hand or fingers, left',
  24: 'ankylosis in hand or fingers, bilateral',
  25: 'ankylosis in hip, right',
  26: 'ankylosis in hip, left',
  27: 'ankylosis in hip, bilateral',
  28: 'ankylosis in knee, right',
  29: 'ankylosis in knee, left',
  30: 'ankylosis in knee, bilateral',
  31: 'ankylosis in shoulder, right',
  32: 'ankylosis in shoulder, left',
  33: 'ankylosis in shoulder, bilateral',
  34: 'ankylosis in wrist, right',
  35: 'ankylosis in wrist, left',
  36: 'ankylosis in wrist, bilateral',
  37: 'anorexia (type of eating disorder)',
  38: 'aortic insufficiency (aortic regurgitation)',
  39: 'aortic valve disease',
  40: 'arrhythmia (irregular heartbeat)',
  41: 'asthma',
  42: 'astragalectomy or talectomy (removal of talus bone in ankle), right',
  43: 'astragalectomy or talectomy (removal of talus bone in ankle), left',
  44: 'astragalectomy or talectomy (removal of talus bone in ankle), bilateral',
  45: "athlete's foot (tinea pedis)",
  46: 'atrial fibrillation (A-fib)',
  47: 'basal cell carcinoma of the skin, head or neck',
  48: 'basal cell carcinoma of the skin, extremities or trunk',
  49: 'bipolar disorder',
  50: 'bladder cancer',
  51: 'bladder stones',
  52: 'bone cancer, including osteosarcoma, head or neck',
  53: 'bone cancer, including osteosarcoma, extremities or trunk',
  54: 'Boutonniere deformity in fingers, right',
  55: 'Boutonniere deformity in fingers, left',
  56: 'Boutonniere deformity in fingers, bilateral',
  57: 'brain cancer, including glioblastoma',
  58: 'breast cancer, right',
  59: 'breast cancer, left',
  60: 'breast cancer, bilateral',
  61: 'bronchiectasis ',
  62: 'bulimia (type of eating disorder)',
  63: 'bunions (hallux valgus), right',
  64: 'bunions (hallux valgus), left',
  65: 'bunions (hallux valgus), bilateral',
  66: 'bursitis in ankle, right',
  67: 'bursitis in ankle, left',
  68: 'bursitis in ankle, bilateral',
  69: 'bursitis in elbow, right',
  70: 'bursitis in elbow, left',
  71: 'bursitis in elbow, bilateral',
  72: 'bursitis in foot, right',
  73: 'bursitis in foot, left',
  74: 'bursitis in foot, bilateral',
  75: 'bursitis in hand or fingers, right',
  76: 'bursitis in hand or fingers, left',
  77: 'bursitis in hand or fingers, bilateral',
  78: 'bursitis in hip, right',
  79: 'bursitis in hip, left',
  80: 'bursitis in hip, bilateral',
  81: 'bursitis in knee, right',
  82: 'bursitis in knee, left',
  83: 'bursitis in knee, bilateral',
  84: 'bursitis in shoulder, right',
  85: 'bursitis in shoulder, left',
  86: 'bursitis in shoulder, bilateral',
  87: 'bursitis in wrist, right',
  88: 'bursitis in wrist, left',
  89: 'bursitis in wrist, bilateral',
  90: 'carpal tunnel syndrome, right',
  91: 'carpal tunnel syndrome, left',
  92: 'carpal tunnel syndrome, bilateral',
  93: 'cervical cancer',
  94: 'cervical spinal stenosis (narrowing of spinal canal in neck)',
  95: 'chloracne',
  96: 'chronic bronchitis',
  97: 'chronic conjunctivitis (pink eye)',
  98: 'chronic fatigue syndrome',
  99: 'chronic kidney disease (CKD)',
  100: 'chronic laryngitis',
  101: 'chronic nephritis (inflammation of kidneys)',
  102: 'chronic obstructive pulmonary disease (COPD)',
  103: "chronic otitis externa (swimmer's ear), right",
  104: "chronic otitis externa (swimmer's ear), left",
  105: "chronic otitis externa (swimmer's ear), bilateral",
  106: 'chronic rhinitis, allergic or non-allergic',
  107: 'chronic sinusitis',
  108: 'chronic suppurative otitis media (CSOM) or mastoiditis, right',
  109: 'chronic suppurative otitis media (CSOM) or mastoiditis, left',
  110: 'chronic suppurative otitis media (CSOM) or mastoiditis, bilateral',
  111: 'chronic urticaria (chronic hives)',
  112: 'cirrhosis (scarring of liver)',
  113: 'claw foot (pes cavus), right',
  114: 'claw foot (pes cavus), left',
  115: 'claw foot (pes cavus), bilateral',
  116: 'colorectal cancer or colon cancer',
  117: 'complete loss of sense of smell (anosmia)',
  118: 'complete loss of sense of taste (ageusia)',
  119: 'congestive heart failure (CHF)',
  120: 'constrictive bronchiolitis (obliterative bronchiolitis)',
  121: 'coronary artery disease (CAD or arteriosclerotic heart disease)',
  122: 'costochondritis',
  123: 'Cranial nerve paralysis or cranial neuritis (inflammation of cranial nerves)',
  124: 'cranial neuralgia (cranial nerve pain)',
  125: "Crohn's disease",
  126: 'cyclothymic disorder (mild bipolar disorder)',
  127: "De Quervain's tenosynovitis (De Quervain's syndrome), right",
  128: "De Quervain's tenosynovitis (De Quervain's syndrome), left",
  129: "De Quervain's tenosynovitis (De Quervain's syndrome), bilateral",
  130: 'decompression illness',
  131: 'deep vein thrombosis (DVT) in leg, right',
  132: 'deep vein thrombosis (DVT) in leg, left',
  133: 'deep vein thrombosis (DVT) in leg, bilateral',
  134: 'degenerative arthritis (osteoarthritis) in ankle, right',
  135: 'degenerative arthritis (osteoarthritis) in ankle, left',
  136: 'degenerative arthritis (osteoarthritis) in ankle, bilateral',
  137: 'degenerative arthritis (osteoarthritis) in elbow, right',
  138: 'degenerative arthritis (osteoarthritis) in elbow, left',
  139: 'degenerative arthritis (osteoarthritis) in elbow, bilateral',
  140: 'degenerative arthritis (osteoarthritis) in foot, right',
  141: 'degenerative arthritis (osteoarthritis) in foot, left',
  142: 'degenerative arthritis (osteoarthritis) in foot, bilateral',
  143: 'degenerative arthritis (osteoarthritis) in hand or fingers, right',
  144: 'degenerative arthritis (osteoarthritis) in hand or fingers, left',
  145: 'degenerative arthritis (osteoarthritis) in hand or fingers, bilateral',
  146: 'degenerative arthritis (osteoarthritis) in hip, right',
  147: 'degenerative arthritis (osteoarthritis) in hip, left',
  148: 'degenerative arthritis (osteoarthritis) in hip, bilateral',
  149: 'degenerative arthritis (osteoarthritis) in knee, right',
  150: 'degenerative arthritis (osteoarthritis) in knee, left',
  151: 'degenerative arthritis (osteoarthritis) in knee, bilateral',
  152: 'degenerative arthritis (osteoarthritis) in shoulder, right',
  153: 'degenerative arthritis (osteoarthritis) in shoulder, left',
  154: 'degenerative arthritis (osteoarthritis) in shoulder, bilateral',
  155: 'degenerative arthritis (osteoarthritis) in wrist, right',
  156: 'degenerative arthritis (osteoarthritis) in wrist, left',
  157: 'degenerative arthritis (osteoarthritis) in wrist, bilateral',
  158: 'depression (major depressive disorder)',
  159: 'deviated septum',
  160: 'diabetes insipidus',
  161: 'diabetes, type 1 or type 2',
  162: 'diabetic nephropathy (diabetic kidney disease)',
  163: 'diabetic peripheral neuropathy, right lower extremities',
  164: 'diabetic peripheral neuropathy, right upper extremities',
  165: 'diabetic peripheral neuropathy, left lower extremities',
  166: 'diabetic peripheral neuropathy, left upper extremities',
  167: 'diabetic peripheral neuropathy, bilateral lower extremities',
  168: 'diabetic peripheral neuropathy, bilateral upper extremities',
  169: 'diabetic retinopathy',
  170: 'dry eye syndrome',
  171: "Dupuytren's contracture (abnormal thickening of tissues in palm of hand), right",
  172: "Dupuytren's contracture (abnormal thickening of tissues in palm of hand), left",
  173: "Dupuytren's contracture (abnormal thickening of tissues in palm of hand), bilateral",
  174: 'dysphagia (difficulty swallowing), associated with neurological disorder',
  175: 'dysphagia (difficulty swallowing), not associated with neurological disorder',
  176: 'ear cancer',
  177: 'eczema (atopic dermatitis)',
  178: 'elbow replacement (elbow arthroplasty), right',
  179: 'elbow replacement (elbow arthroplasty), left',
  180: 'elbow replacement (elbow arthroplasty), bilateral',
  181: 'emphysema',
  182: 'endometriosis',
  183: 'enlarged prostate (benign prostatic hyperplasia or BPH)',
  184: 'epididymitis',
  185: 'epiphora (watery eyes)',
  186: 'erectile dysfunction (ED)',
  187: 'esophageal cancer',
  188: 'esophageal stricture (narrowing of esophagus)',
  189: 'eye cancer, including melanoma of the eye',
  190: 'fecal incontinence (loss of bowel control)',
  191: 'female sexual arousal disorder (FSAD)',
  192: 'femoral hernia (hernia in thigh area)',
  193: 'fibromyalgia',
  194: 'flatfoot (pes planus), right',
  195: 'flatfoot (pes planus), left',
  196: 'flatfoot (pes planus), bilateral',
  197: 'foot fracture (tarsal or metatarsal fracture), right',
  198: 'foot fracture (tarsal or metatarsal fracture), left',
  199: 'foot fracture (tarsal or metatarsal fracture), bilateral',
  200: 'frostbite in foot, right',
  201: 'frostbite in foot, left',
  202: 'frostbite in foot, bilateral',
  203: 'frostbite in hand or fingers, right',
  204: 'frostbite in hand or fingers, left',
  205: 'frostbite in hand or fingers, bilateral',
  206: 'gallstones (cholelithiasis)',
  207: "gamekeeper's thumb, right",
  208: "gamekeeper's thumb, left",
  209: "gamekeeper's thumb, bilateral",
  210: 'ganglion cyst in hand or fingers, right',
  211: 'ganglion cyst in hand or fingers, left',
  212: 'ganglion cyst in hand or fingers, bilateral',
  213: 'ganglion cyst in wrist, right',
  214: 'ganglion cyst in wrist, left',
  215: 'ganglion cyst in wrist, bilateral',
  216: 'generalized anxiety disorder (GAD)',
  217: 'GERD (gastroesophageal reflux disease)',
  218: 'glaucoma',
  219: "golfer's elbow (medial epicondylitis), right",
  220: "golfer's elbow (medial epicondylitis), left",
  221: "golfer's elbow (medial epicondylitis), bilateral",
  222: 'gonococcal arthritis',
  223: 'gout in foot, right',
  224: 'gout in foot, left',
  225: 'gout in foot, bilateral',
  226: 'gout in wrist, right',
  227: 'gout in wrist, left',
  228: 'gout in wrist, bilateral',
  229: 'granulomatous disease',
  230: 'greater trochanteric pain syndrome (lateral hip pain), right',
  231: 'greater trochanteric pain syndrome (lateral hip pain), left',
  232: 'greater trochanteric pain syndrome (lateral hip pain), bilateral',
  233: 'hallux rigidus (big toe arthritis), bilateral',
  234: 'hallux rigidus (big toe arthritis), left',
  235: 'hallux rigidus (big toe arthritis), right',
  236: 'hammer toe, right',
  237: 'hammer toe, left',
  238: 'hammer toe, bilateral',
  239: "Hansen's disease (leprosy)",
  240: 'hearing loss, other than tinnitus',
  241: 'heart attack (myocardial infarction)',
  242: 'heart bypass surgery (coronary artery bypass graft)',
  243: 'hemorrhoids',
  244: 'hepatitis B',
  245: 'hepatitis C',
  246: 'herpes (herpes simplex virus or HSV)',
  247: 'hiatal hernia',
  248: 'hip impingement (femoroacetabular impingement or FAI), right',
  249: 'hip impingement (femoroacetabular impingement or FAI), left',
  250: 'hip impingement (femoroacetabular impingement or FAI), bilateral',
  251: 'hip replacement (hip arthroplasty), right',
  252: 'hip replacement (hip arthroplasty), left',
  253: 'hip replacement (hip arthroplasty), bilateral',
  254: 'hip sprain, right',
  255: 'hip sprain, left',
  256: 'hip sprain, bilateral',
  257: 'HIV-related illness',
  258: 'Hodgkin lymphoma',
  259: 'hydrocele, right',
  260: 'hyperhidrosis (excessive sweating)',
  261: 'hypertension (high blood pressure)',
  262: 'hyperthyroidism (overactive thyroid)',
  263: 'hypopharyngeal cancer',
  264: 'hypothyroidism (underactive thyroid)',
  265: 'hysterectomy (removal of uterus)',
  266: 'hysterectomy and ovariectomy (removal of uterus and both ovaries)',
  267: 'illness anxiety disorder (hypochondria)',
  268: 'immersion foot, right',
  269: 'immersion foot, left',
  270: 'immersion foot, bilateral',
  271: 'inguinal hernia (hernia in groin)',
  272: 'interstitial lung disease (ILD), including asbestosis',
  273: 'intervertebral disc syndrome (IVDS), back',
  274: 'intervertebral disc syndrome (IVDS), neck',
  275: 'iron deficiency anemia',
  276: 'irritable bowel syndrome (IBS)',
  277: 'jaw cancer',
  278: 'keratinization skin disorders',
  279: 'kidney cancer (renal cancer), right',
  280: 'kidney cancer (renal cancer), left',
  281: 'kidney cancer (renal cancer), bilateral',
  282: 'kidney removal (nephrectomy)',
  283: 'kidney stones (nephrolithiasis)',
  284: 'kidney transplant',
  285: 'knee hyperextension (genu recurvatum), right',
  286: 'knee hyperextension (genu recurvatum), left',
  287: 'knee hyperextension (genu recurvatum), bilateral',
  288: 'knee instability, right',
  289: 'knee instability, left',
  290: 'knee instability, bilateral',
  291: 'knee or patellar dislocation, right',
  292: 'knee or patellar dislocation, left',
  293: 'knee or patellar dislocation, bilateral',
  294: 'knee or patellar fracture, right',
  295: 'knee or patellar fracture, left',
  296: 'knee or patellar fracture, bilateral',
  297: 'knee replacement (knee arthroplasty), right',
  298: 'knee replacement (knee arthroplasty), left',
  299: 'knee replacement (knee arthroplasty), bilateral',
  300: 'knee strain or sprain, right',
  301: 'knee strain or sprain, left',
  302: 'knee strain or sprain, bilateral',
  303: 'labyrinthitis (type of inner ear infection), right',
  304: 'labyrinthitis (type of inner ear infection), left',
  305: 'labyrinthitis (type of inner ear infection), bilateral',
  306: 'laryngeal cancer (cancer of larynx)',
  307: 'leukemia',
  308: 'liver cancer, including hepatocellular carcinoma (HCC)',
  309: 'loss of both eyes',
  310: 'loss of teeth due to bone loss',
  311: 'lower back sprain (lumbosacral sprain)',
  312: 'lower back strain (lumbosacral strain)',
  313: 'lumbar spinal stenosis (narrowing of spinal canal in lower back)',
  314: 'lung cancer',
  315: 'lupus',
  316: 'lymphatic filariasis',
  317: 'malaria',
  318: 'malignant tumor in spinal cord',
  319: 'mallet finger, right',
  320: 'mallet finger, left',
  321: 'mallet finger, bilateral',
  322: 'melanoma of the skin',
  323: "Meniere's disease",
  324: 'meniscectomy (removal of meniscus), right',
  325: 'meniscectomy (removal of meniscus), left',
  326: 'meniscectomy (removal of meniscus), bilateral',
  327: 'meniscus tear, right',
  328: 'meniscus tear, left',
  329: 'meniscus tear, bilateral',
  330: 'metatarsalgia (pain in ball of foot), right',
  331: 'metatarsalgia (pain in ball of foot), left',
  332: 'metatarsalgia (pain in ball of foot), bilateral',
  333: 'middle back sprain (thoracic sprain)',
  334: 'middle back strain (thoracic strain)',
  335: 'migraines (headaches)',
  336: 'mitral regurgitation',
  337: 'mitral valve prolapse (Barlow syndrome)',
  338: "Morton's neuroma, right",
  339: "Morton's neuroma, left",
  340: "Morton's neuroma, bilateral",
  341: 'multiple myeloma, head or neck',
  342: 'multiple myeloma, extremities or trunk',
  343: 'multiple sclerosis (MS)',
  344: 'muscle hernia',
  345: 'myasthenia gravis',
  346: 'nasopharyngeal cancer',
  347: 'neck sprain (cervical sprain)',
  348: 'neck strain (cervical strain)',
  349: 'nerve-related soft-tissue sarcoma, head or neck',
  350: 'nerve-related soft-tissue sarcoma, extremities or trunk',
  351: 'neurogenic bladder',
  352: 'non-diabetic peripheral neuropathy, right lower extremities',
  353: 'non-diabetic peripheral neuropathy, right upper extremities',
  354: 'non-diabetic peripheral neuropathy, left lower extremities',
  355: 'non-diabetic peripheral neuropathy, left upper extremities',
  356: 'non-diabetic peripheral neuropathy, bilateral lower extremities',
  357: 'non-diabetic peripheral neuropathy, bilateral upper extremities',
  358: 'non-Hodgkin lymphoma',
  359: 'obsessive compulsive disorder (OCD)',
  360: 'oral cavity squamous cell carcinoma',
  361: 'orchitis (inflammation of testicles)',
  362: 'oropharyngeal cancer',
  363: 'osteomyelitis',
  364: 'otitis media (middle ear infection), right',
  365: 'otitis media (middle ear infection), left',
  366: 'otitis media (middle ear infection), bilateral',
  367: 'ovarian adhesions',
  368: 'ovarian cancer, right',
  369: 'ovarian cancer, left',
  370: 'ovarian cancer, bilateral',
  371: 'ovarian cysts',
  372: 'ovariectomy (removal of one or both ovaries)',
  373: 'pancreatic cancer',
  374: 'panic disorder',
  375: 'paranasal sinus and nasal cavity cancer',
  376: "Parkinson's disease",
  377: 'patellar or quadriceps tendon rupture, right',
  378: 'patellar or quadriceps tendon rupture, left',
  379: 'patellar or quadriceps tendon rupture, bilateral',
  380: 'patellofemoral pain syndrome, right',
  381: 'patellofemoral pain syndrome, left',
  382: 'patellofemoral pain syndrome, bilateral',
  383: 'PCL tear (posterior cruciate ligament tear), right',
  384: 'PCL tear (posterior cruciate ligament tear), left',
  385: 'PCL tear (posterior cruciate ligament tear), bilateral',
  386: 'penile cancer',
  387: 'perforated eardrum (perforated tympanic membrane), right',
  388: 'perforated eardrum (perforated tympanic membrane), left',
  389: 'perforated eardrum (perforated tympanic membrane), bilateral',
  390: 'persistent depressive disorder (dysthymic disorder)',
  391: 'pharyngeal cancer (throat cancer) ',
  392: 'plantar fasciitis, left',
  393: 'plantar fasciitis, right',
  394: 'plantar fasciitis, bilateral',
  395: 'plantar warts (foot warts), right',
  396: 'plantar warts (foot warts), left',
  397: 'plantar warts (foot warts), bilateral',
  398: 'pleurisy (pleuritis) ',
  399: 'pneumococcal arthritis',
  400: 'polycystic ovary syndrome (PCOS)',
  401: 'post-traumatic arthritis in ankle, right',
  402: 'post-traumatic arthritis in ankle, left',
  403: 'post-traumatic arthritis in ankle, bilateral',
  404: 'post-traumatic arthritis in elbow, right',
  405: 'post-traumatic arthritis in elbow, left',
  406: 'post-traumatic arthritis in elbow, bilateral',
  407: 'post-traumatic arthritis in foot, right',
  408: 'post-traumatic arthritis in foot, left',
  409: 'post-traumatic arthritis in foot, bilateral',
  410: 'post-traumatic arthritis in hand or fingers, right',
  411: 'post-traumatic arthritis in hand or fingers, left',
  412: 'post-traumatic arthritis in hand or fingers, bilateral',
  413: 'post-traumatic arthritis in hip, right',
  414: 'post-traumatic arthritis in hip, left',
  415: 'post-traumatic arthritis in hip, bilateral',
  416: 'post-traumatic arthritis in knee, right',
  417: 'post-traumatic arthritis in knee, left',
  418: 'post-traumatic arthritis in knee, bilateral',
  419: 'post-traumatic arthritis in shoulder, right',
  420: 'post-traumatic arthritis in shoulder, left',
  421: 'post-traumatic arthritis in shoulder, bilateral',
  422: 'post-traumatic arthritis in wrist, right',
  423: 'post-traumatic arthritis in wrist, left',
  424: 'post-traumatic arthritis in wrist, bilateral',
  425: 'poststreptococcal arthritis',
  426: 'premature ventricular contractions',
  427: 'proctitis (inflammation of rectum)',
  428: 'prostate cancer',
  429: 'prostatitis (inflammation of prostate)',
  430: 'pseudofolliculitis barbae (razor bumps, shave bumps, or ingrown hairs)',
  431: 'psoriasis',
  432: 'PTSD (post-traumatic stress disorder), combat-related',
  433: 'PTSD (post-traumatic stress disorder), non-combat-related',
  434: 'pulmonary fibrosis',
  435: 'pulmonary granuloma (lung nodule)  ',
  436: 'radiculopathy, right lower extremities',
  437: 'radiculopathy, left lower extremities',
  438: 'radiculopathy, bilateral lower extremities',
  439: 'radiculopathy, right upper extremities',
  440: 'radiculopathy, left upper extremities',
  441: 'radiculopathy, bilateral upper extremities',
  442: "Raynaud's disease",
  443: 'rheumatoid arthritis',
  444: 'rib fracture, right',
  445: 'rib fracture, left',
  446: 'rib fracture, bilateral',
  447: 'rib removal',
  448: 'right ventricular hypertrophy (RVH)',
  449: 'left ventricular hypertrophy (LVH)',
  450: 'biventricular hypertrophy',
  451: 'ringworm (dermatophytosis)',
  452: 'rotator cuff tear, right',
  453: 'rotator cuff tear, left',
  454: 'rotator cuff tear, bilateral',
  455: 'salivary gland cancer',
  456: 'sarcoidosis',
  457: 'scars, extremities or trunk',
  458: 'scars, head, face or neck',
  459: 'schizophrenia',
  460: 'shin splints, right',
  461: 'shin splints, left',
  462: 'shin splints, bilateral',
  463: 'shoulder dislocation, right',
  464: 'shoulder dislocation, left',
  465: 'shoulder dislocation, bilateral',
  466: 'shoulder impingement syndrome, right',
  467: 'shoulder impingement syndrome, left',
  468: 'shoulder impingement syndrome, bilateral',
  469: 'shoulder replacement (shoulder arthroplasty), right',
  470: 'shoulder replacement (shoulder arthroplasty), left',
  471: 'shoulder replacement (shoulder arthroplasty), bilateral',
  472: 'shoulder strain, right',
  473: 'shoulder strain, left',
  474: 'shoulder strain, bilateral',
  475: 'sickle cell anemia',
  476: 'SLAP tear (superior labral anterior to posterior tear), right',
  477: 'SLAP tear (superior labral anterior to posterior), left',
  478: 'SLAP tear (superior labral anterior to posterior tear), bilateral',
  479: 'sleep apnea',
  480: 'small intestine cancer',
  481: 'soft-tissue sarcoma of muscle, fat, or fibrous connective tissue, head or neck',
  482: 'soft-tissue sarcoma of muscle, fat, or fibrous connective tissue, extremities or trunk',
  483: 'somatic symptom disorder (SSD)',
  484: 'spinal arthritis, back',
  485: 'spinal arthritis, neck',
  486: 'spondylolisthesis, back',
  487: 'spondylolisthesis, neck',
  488: 'squamous cell carcinoma of the skin, head or neck ',
  489: 'squamous cell carcinoma of the skin, extremities or trunk',
  490: 'stomach cancer',
  491: 'stress fracture in leg, right',
  492: 'stress fracture in leg, left',
  493: 'stress fracture in leg, bilateral',
  494: 'syphilitic arthritis',
  495: 'tachycardia',
  496: 'tailbone (coccyx) removal',
  497: 'temporomandibular disorder, including TMJ',
  498: 'tendinosis in hand or fingers, right',
  499: 'tendinosis in hand or fingers, left',
  500: 'tendinosis in hand or fingers, bilateral',
  501: 'tendonitis (tendinitis) in ankle, right',
  502: 'tendonitis (tendinitis) in ankle, left',
  503: 'tendonitis (tendinitis) in ankle, bilateral',
  504: 'tendonitis (tendinitis) in elbow, right',
  505: 'tendonitis (tendinitis) in elbow, left',
  506: 'tendonitis (tendinitis) in elbow, bilateral',
  507: 'tendonitis (tendinitis) in foot, right',
  508: 'tendonitis (tendinitis) in foot, left',
  509: 'tendonitis (tendinitis) in foot, bilateral',
  510: 'tendonitis (tendinitis) in hand or fingers, right',
  511: 'tendonitis (tendinitis) in hand or fingers, left',
  512: 'tendonitis (tendinitis) in hand or fingers, bilateral',
  513: 'tendonitis (tendinitis) in hip, right',
  514: 'tendonitis (tendinitis) in hip, left',
  515: 'tendonitis (tendinitis) in hip, bilateral',
  516: 'tendonitis (tendinitis) in knee, right',
  517: 'tendonitis (tendinitis) in knee, left',
  518: 'tendonitis (tendinitis) in knee, bilateral',
  519: 'tendonitis (tendinitis) in shoulder, right',
  520: 'tendonitis (tendinitis) in shoulder, left',
  521: 'tendonitis (tendinitis) in shoulder, bilateral',
  522: 'tendonitis (tendinitis) in wrist, right',
  523: 'tendonitis (tendinitis) in wrist, left',
  524: 'tendonitis (tendinitis) in wrist, bilateral',
  525: 'tennis elbow (lateral epicondylitis), right',
  526: 'tennis elbow (lateral epicondylitis), left',
  527: 'tennis elbow (lateral epicondylitis), bilateral',
  528: 'tenosynovitis in hand or fingers, right',
  529: 'tenosynovitis in hand or fingers, left',
  530: 'tenosynovitis in hand or fingers, bilateral',
  531: 'tenosynovitis in wrist, right',
  532: 'tenosynovitis in wrist, left',
  533: 'tenosynovitis in wrist, bilateral',
  534: 'testicular cancer, right',
  535: 'testicular cancer, left',
  536: 'testicular cancer, bilateral',
  537: 'thyroid cancer',
  538: 'tibia or fibula fracture, right',
  539: 'tibia or fibula fracture, left',
  540: 'tibia or fibula fracture, bilateral',
  541: 'Tietze syndrome',
  542: 'tinnitus (ringing or hissing in ears)',
  543: 'tongue cancer',
  544: 'trachea cancer (cancer of windpipe)',
  545: 'traumatic brain injury (TBI)',
  546: 'trigger finger, right',
  547: 'trigger finger, left',
  548: 'trigger finger, bilateral',
  549: 'typhoid arthritis',
  550: 'ulcerative colitis',
  551: 'ureteral cancer (ureter cancer)',
  552: 'urethritis',
  553: 'urinary incontinence (loss of bladder control)',
  554: 'uterine cancer',
  555: 'vaginal cancer',
  556: 'varicocele, right',
  557: 'varicocele, left',
  558: 'varicocele, bilateral',
  559: 'varicose veins in leg',
  560: 'varicose veins, other than in leg',
  561: 'vascular soft-tissue sarcoma, including angiosarcoma, head or neck ',
  562: 'vascular soft-tissue sarcoma, including angiosarcoma, extremities or trunk',
  563: 'ventral hernia (hernia in abdomen)',
  564: 'visual impairment, including blurry vision, blindness and double vision',
  565: 'vitiligo',
  566: 'vulvar cancer',
  567: 'weak foot, bilateral',
  568: 'Wolff-Parkinson-White syndrome',
  569: 'wrist fracture, right',
  570: 'wrist fracture, left',
  571: 'wrist fracture, bilateral',
  572: 'wrist replacement (wrist arthroplasty), right',
  573: 'wrist replacement (wrist arthroplasty), left',
  574: 'wrist replacement (wrist arthroplasty), bilateral',
  575: 'wrist sprain, right',
  576: 'wrist sprain, left',
  577: 'wrist sprain, bilateral',
};
